import { useEffect, useState, useContext } from "react";
import AdminContext from "@context";
import { AddOption } from "@services";

export default (category, getValue) => {
	const {
		auth: { token },
		catalogos,
		setCatalogos,
	} = useContext(AdminContext);
	const [label, setLabel] = useState("");
	const [description, setDescription] = useState("");
	const [showError, setShowError] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [messageError, setMessageError] = useState("");

	const handleCreateOption = () => {
		AddOption({ category, label, description, token })
			.then(({ id, label }) => {
				const list = catalogos[category];
				const newList = [...list, { id, label, new: true }];

				setCatalogos({
					...catalogos,
					[category]: newList,
				});

				getValue && getValue({
					checked: true,
					value: label,
				});
			})
			.catch(({ message }) => {
				setMessageError(message);
				setShowError(true);
				setTimeout(() => {
					setShowError(false);
				}, 5000);
			});
	};

	const handleCancel = () => {
		getValue(null);
	}

	useEffect(() => {
		setEnableButton(label.length > 2 && description.length > 2);
	}, [label, description]);

	return {
		enableButton,
		label,
		setLabel,
		description,
		setDescription,
		showError,
		messageError,
		handleCreateOption,
		handleCancel
	};
};
