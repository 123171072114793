import React from "react";
import { Base } from "./styles";
import { Loader, Svg, Tooltip, Text } from "@components";

import EditIcon from "@assets/icons/settings.svg";
import Delete from "@assets/icons/delete.svg";
import Preview from "@assets/icons/eye.svg";
import Close from "@assets/icons/close.svg";
import Back from "@assets/icons/back.svg";
import Back2 from "@assets/icons/back2.svg";

const dataIcon = {
	edit: EditIcon,
	delete: Delete,
	show: Preview,
	close: Close,
	back: Back,
	back2: Back2,
};

export default ({
	children,
	mobile,
	loading,
	icon,
	direction,
	w,
	sizeIcon,
	tooltip,
	small,
	...props
}) => {
	return (
		<>
			{tooltip ? (
				<Tooltip
					direction={direction}
					w={w}
					element={
						<Base small={small} icon={icon} {...props}>
							{loading && <Loader w={20} />}
							{icon && <Svg icon={dataIcon[icon]} width={small ? 15 : sizeIcon || 20} height={small ? 15 : sizeIcon || 20} />}
						</Base>
					}
				>
					<Text fs={10} fw={500} align="center">
						{tooltip}
					</Text>
				</Tooltip>
			) : (
				<Base icon={icon} {...props}>
					{loading && <Loader w={20} />}
					{icon && <Svg icon={dataIcon[icon]} width={20} height={20} />}
				</Base>
			)}
		</>
	);
};
