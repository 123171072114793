import React from 'react'
import st, { css } from 'styled-components'
import { switchProp } from "styled-tools";
import { breakpoints } from "@constants/css"

const defineBreakpoint = (point,breakpoint) => {
	const percent = breakpoint * 5;
	return css`
		@media screen and (min-width: ${breakpoints[point]}px) {
			min-width: ${percent > 100 ? 100 : percent}%;
			width: ${percent > 100 ? 100 : percent}%;
		}
	`
}

const switchAlign = switchProp('self', {
	around: 'align-self: space-around;',
	between: 'align-self: space-between;',
	start: 'align-self: start;',
	center: 'align-self: center;',
	end: 'align-self: end;'
})

const Column = st.div`
	display: block;
	max-width: ${ ({autofit}) => autofit ? 'auto' : '100%'};
	width: ${ ({autofit}) => autofit ? 'auto' : '100%'};

	${ ({self}) => self ? switchAlign : '' }

	${ ({xs}) => xs ? defineBreakpoint('xs', xs) : '' }
	${ ({sm}) => sm ? defineBreakpoint('sm', sm) : '' }
	${ ({md}) => md ? defineBreakpoint('md', md) : '' }
	${ ({lg}) => lg ? defineBreakpoint('lg', lg) : '' }
	${ ({hd}) => hd ? defineBreakpoint('hd', hd) : '' }
`

export default ({children, ...props}) => {
	return (
		<Column {...props}>
			{children}
		</Column>
	)
}
