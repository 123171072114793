import st, { css } from "styled-components";

const Wrapper = st.div`
	display: block;
	position: relative;
`;

const Container = st.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	overflow-y: auto;

	align-items: center;
	align-content: center;
	justify-content: center;
	z-index: 999999;

	&, & * {
		opacity: ${({ show }) => (show ? "1" : "0")};
		transform: scale(${({ show }) => (show ? "1" : "0")});
		pointer-events: ${({ show }) => (show ? "all" : "none")};
	}
`;

const Overlay = st.div`
	background-color: rgba(255, 255, 255, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
`;

const Content = st.div`
	width: 450px;
	min-width: 450px;
	display: flex;
	flex-wrap: wrap;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.3);
	padding: 1rem;
	z-index: 9999999;
	`;

const HeaderWrapper = st.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

const Control = st.div`
	display: block;
	position: relative;
	color: #2B3674;
	background-color: transparent;
	border-radius: 15px;
	width: 90px;
	padding: 0.25rem 1rem;

	&:hover {
		background-color: #F4F7FE;
		color: #4318FF;
	}

	.icon {
		pointer-events: none;
		position: absolute;
		right: 0.5rem;
		top: 8px;
	}
`;

const Select = st.select`
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	color: inherit;
	background-color: transparent;
	box-shadow: none;
	border: none;

	cursor: pointer;
	font-size: inherit;

	&:focus,
	&:active,
	&:hover {
		outline: 0;
	}

	width: 100%;
	height: 30px;
	border-radius: 10px;
`;

const Grid = st.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(7, 1fr);
	grid-auto-rows: auto;
	margin-top: 1rem;
`;

const GridLabel = st.small`
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	text-align: center;

	color: #2B3674;
	font-weight: 500;
	padding: 0.25rem 0.5rem;
	border-radius: 10px;
	margin-bottom: 0.5rem;
	user-select: none;

	${({ active }) =>
		active &&
		css`
			background-color: #f4f7fe;
			color: #4318ff;
		`}

`;

const GridButton = st.button`
	display: inline-flex;
	width: 60px;
	height: 60px;
	min-width: 60px;
	min-height: 60px;
	border-radius: 5rem;

	align-items: center;
	align-content: center;
	justify-content: center;
	text-align: center;
	font-size: inherit;
	cursor: pointer;

	background-color: transparent;
	border: 1px solid transparent;
	color: #2B3674;

	&:hover {
		background-color: #F4F7FE;
		color: #4318FF;
	}

	opacity: ${({ show }) => (show ? 1 : 0)};
	transition: opacity 200ms linear;

	${({ active }) =>
		active &&
		css`
			&,
			&:focus,
			&:hover {
				background-color: #4318ff;
				color: white;
			}
		`}

	${({ index, inicioSemana }) =>
		!index &&
		inicioSemana &&
		css`
			grid-column: ${inicioSemana + 1};
		`}

	&:hover,
	&:focus,
	&:active {
		outline: 0;
	}
`;

const btn = css`
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	height: 40px;
	border-radius: 15px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: all 200ms ease;
	padding: 0 1rem;
	font-size: inherit;

	&,
	&:focus,
	&:active {
		outline: 0;

		* {
			pointer-events: none;
		}
	}
`;

const Aceptar = st.button`
	${btn}
	color: #0066ff;

	&:hover {
		background-color: #F4F7FE;
	}
`;

const Cancelar = st.button`
	${btn}
	color: #A3AED0;

	&:hover {
		color: #fd4949;
	}
`;

export {
	Wrapper,
	Container,
	Overlay,
	Content,
	HeaderWrapper,
	Control,
	Select,
	Grid,
	GridButton,
	GridLabel,
	Aceptar,
	Cancelar,
};
