import { request } from "@services/api";
import endpoints from "@services/endpoints";

const getBlogs = (token, uuid) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    const {
      BLOGS: { GET_BLOGS },
    } = endpoints;
    request
      .post(GET_BLOGS, { uuid })
      .then(({ data }) => resolve(data.data))
      .catch(() => reject({ error: "no existen blogs" }));
  });

export default getBlogs;
