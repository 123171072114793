import st from 'styled-components'

const WrapperLayout = st.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-wrap: nowrap;
`;

export { WrapperLayout }
