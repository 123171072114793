import React from 'react';
import st from 'styled-components'

const Note = st.div`
	display: block;
	width: 100%;
	border-radius: 5px 10px 10px;
	padding: 0.5rem;
	font-size: 12px;
	background-color: rgba(0,0,255,0.05);
	color: #252323;
	margin-bottom: 2rem;
`

export default ({ children, ...props }) => (
	<Note {...props}>{children}</Note>
)
