import { request } from "@services/api";
import endpoints from "@services/endpoints";
import { checkJWToken } from "@services";

const {
  ADMIN: { DELETE_PROSPECTO },
} = endpoints;

const deleteProspecto = (id, token) =>
  new Promise((resolve, reject) => {
		if(!+id) {
			reject({message: 'invalid id prospecto'})
		}

    request.setAuthorization(token);
    checkJWToken(token)
      .then(() => {
        request
          .post(`${DELETE_PROSPECTO}/${id}`)
          .then((res) => resolve(res.data))
          .catch(err => reject(err));
      })
      .catch(({ response }) => reject(response));
  });

export default deleteProspecto;
