import React from 'react';
import  {Flex,Col,Tooltip} from '@components'

const InputHelper = ({children, tooltip, ...props}) => (
	<Flex className="mt:15" {...props}>
		<Col xs={20} md={18}>
			{children}
		</Col>
		<Col xs={20} md={2} className="ph:05">
			<Tooltip icon="info" w={250} direction="right">
				{tooltip}
			</Tooltip>
		</Col>
	</Flex>
)

export default InputHelper
