import React from 'react';
import { Graphic } from './styles';

export default ({children, ...props}) => {
    return (
        <Graphic {...props}>
            {children}
        </Graphic>
    )
}
