import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  AFILIADOS: { GET_RECOMENDADOS },
} = endpoints;

const getRecomendados = (unique_id, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    request
      .post(GET_RECOMENDADOS, { unique_id })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });

export default getRecomendados;
