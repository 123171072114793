import React, { useState, useEffect } from "react";
import { Grid, GridButton, GridLabel } from "./styles";

const CalendarGrid = ({ getValue, month, year, day }) => {
	const labels = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
	const [dias, setDias] = useState([]);
	const [diaSemana, setDiaSemana] = useState(0);

	useEffect(() => {
		const date = new Date(year, month, day);
		const semana = new Date(year, month, 1);
		const totalDias = new Date(year, month + 1, 0).getDate();

		const dia = date.getDate();
		const inicioSemana = semana.getDay();

		setDiaSemana(date.getDay());
		updateCalendar(dia, totalDias, inicioSemana);
	}, [month, year, day]);

	const updateCalendar = (dia, totalDias, inicioSemana) => {
		const d = [];

		for (let i = 0; i < totalDias; i++) {
			d.push(
				<GridButton
					key={i}
					index={i}
					show={true}
					inicioSemana={inicioSemana}
					active={dia === i + 1}
					onClick={() => getValue(i + 1)}
				>
					{i + 1}
				</GridButton>
			);
		}

		setDias(d);
	};

	return (
		<>
			<Grid>
				{labels.length &&
					labels.map((d, key) => (
						<GridLabel active={key === diaSemana} key={key}>
							{d}
						</GridLabel>
					))}
			</Grid>
			<Grid>{dias?.length && dias.map((d) => d)}</Grid>
		</>
	);
};

export default CalendarGrid;
