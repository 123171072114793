import { request } from "@services/api";
import endpoints from "@services/endpoints";

const Login = ({ email, password, uuid }) =>
  new Promise((resolve, reject) => {
    const { LOGIN } = endpoints;
    const response = request.post(LOGIN, {
      email,
      password,
      uuid,
    });

    response
      .then(({ data }) => {
        const {
          profile: { name: username, fingerprint: uuid, email, role, unique_code },
          jwt: { token },
        } = data;

        request.setAuthorization(token);
        resolve({
          token,
          username,
          email,
          role,
          uuid,
					unique_code
        });
      })
      .catch(({ response: { status } }) => {
        if (status === 402) {
          reject({ error: "Ya existe una sesión activa" });
        } else {
          reject({ error: "credenciales de acceso inválidas" });
        }
      });
  });

export default Login;
