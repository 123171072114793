export default {
	LOGIN: '/login',
	LOGOUT: '/logoutUser',
	VALIDATE_TOKEN: '/verifyToken',
	ACTIVAR_CUENTA: '/activateAccount',
	BLOGS: {
		CATEGORIES: '/blogs/categories',
		GET_BLOGS: '/blogs/get',
		ADD_BLOG: '/blogs/save',
		DELETE_BLOG: '/blogs/delete',
		UPLOAD_FILES: '/uploadFiles'
	},
	ADMIN: {
		GET_PROSPECTOS: '/admin/getProspectos',
		SAVE_PROSPECTO: '/admin/saveProspecto',
		DELETE_PROSPECTO: '/admin/deleteProspecto',
		SET_CLIENTE: '/admin/convertirCliente',
		GET_PROSPECTO_ID: '/admin/getProspectoById'
	},
	AFILIADOS: {
		GET_RECOMENDADOS: '/afiliados/recomendados',
		GET_PERFIL: '/afiliados/perfil',
		SAVE_PERFIL: '/afiliados/guardar-perfil',
		UPDATE_ACCESOS: '/afiliados/actualizar-accesos',
	}
}
