import { request } from "@services/api";
import endpoints from "@services/endpoints";

const Logout = ({ email, token }) =>
	new Promise((resolve, reject) => {
		const { LOGOUT } = endpoints;
		const response = request.post(LOGOUT, { email });
		request.setAuthorization(token);

		response
			.then(() => resolve(true))
			.catch(() => reject({ error: "no se pudo cerrar sesión" }));
	});

export default Logout;
