import styled, { css } from "styled-components";

const Portal = styled.div`
	display: flex;
	align-items: start;
	align-content: center;
	justify-content: center;
	overflow-x: hidden;
	z-index: 99999;

	padding-top: 50px;
	padding-bottom: 50px;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.95);
	transition: opacity 100ms ease;

	opacity: ${({ show }) => !show ? 0 : 1};
	pointer-events: ${({ show }) => !show ? 'none' : 'all'};
`;

const Modal = styled.div`
	display: block;
	width: ${({ width }) => width || 500}px;
	border-radius: 5px;
	min-height: 100px;
	z-index: 9999999;
	background-color: #ffffff;
	color: #232525;
	box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.2);
`

const ModalHeader = styled.div`
	position: relative;
	display: block;
	padding: 40px 5% 0;
`

const ModalContent = styled.div`
	position: relative;
	display: block;
	padding: 0;
`

const ModalFooter = styled.div`
	position: relative;
	display: block;
	padding: 40px 5%;
`

export { Portal, Modal, ModalHeader, ModalFooter, ModalContent };
