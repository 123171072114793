import React from "react";
import { Svg } from "@components";
import { Check, Label, WrapCheck } from "./styles";

import checkEmpty from "@assets/icons/check-empty.svg";
import checkFill from "@assets/icons/check-fill.svg";

export default ({ children, name, id, getValue, value, selected = false }) => {
	const handleOnChange = (checked) => {
		getValue && getValue({ checked, value });
	};
	return (
		<WrapCheck>
			<Label htmlFor={id}>
				<Check
					type="checkbox"
					id={id}
					name={name}
					onChange={({target}) => handleOnChange(target.checked)}
					value={value}
					checked={selected}
				/>

				<Svg
					id="checked"
					icon={checkFill}
					width={18}
					height={18}
					className="mr:05"
				/>
				<Svg
					id="empty"
					icon={checkEmpty}
					width={18}
					height={18}
					className="mr:05"
				/>
				{children}
			</Label>
		</WrapCheck>
	);
};
