import { request } from "@services/api";
import endpoints from "@services/endpoints";
import { checkJWToken } from "@services";

const {
  ADMIN: { GET_PROSPECTOS },
} = endpoints;

const getProspectos = token =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);

    checkJWToken(token)
      .then(() => {
        request
          .get(GET_PROSPECTOS)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => reject(err));
      })
      .catch(({ response }) => reject(response));
  });

export default getProspectos;
