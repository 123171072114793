import { request } from "@services/api";
import endpoints from "@services/endpoints";
import { checkJWToken } from "@services";

const {
	ADMIN: { GET_PROSPECTO_ID },
} = endpoints;

const getProspecto = (id, token) =>
	new Promise((resolve, reject) => {
		request.setAuthorization(token);

		checkJWToken(token)
			.then(() => {
				request
					.get(`${GET_PROSPECTO_ID}/${id}`)
					.then(({ data: { data } }) => {
						const {
							country,
							state,
							postal,
							localty,
							city,
							street,
							noInt,
							noExt,
						} = data;
						resolve({
							...data,
							address: {
								country,
								state,
								postal,
								localty,
								city,
								street,
								noInt,
								noExt,
							},
						});
					})
					.catch((err) => reject(err));
			})
			.catch(({ response }) => reject(response));
	});

export default getProspecto;
