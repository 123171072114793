import st from 'styled-components'

const Button = st.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
	cursor: pointer;
	transition background-color 100ms linear;

	&:hover {
		background-color: rgba(0,0,0,0.05);
	}
`

const Quantity = st.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	justify-content: space-between;
	height: 40px;
	border-bottom: solid;
	border-bottom-width: ${props => (props.hasError ? "2px" : "1px")};
	border-bottom-color: ${props => {
    if (props.hasError) {
      return "#E82A4D"
    }
    if (props.disabled) {
      return "rgba(83,86,90,0.4)"
    } else {
      return "#768692"
    }
  }};
	background-color: #fcfcfc;
	margin-bottom: 20px;
`

const QuantityWrapper = st.div`
	display: inline-flex;
	width: 120px;
	min-width: 120px;
`

const Label = st.label`
	user-select: none;
	pointer-events: none;
	color: #252323;
	font-size: 14px;
	min-width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export {
	Label,
	Button,
	Quantity,
	QuantityWrapper
}
