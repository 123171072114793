import styled from "styled-components";

const WrappInput = styled.div`
	height: auto;
	width: 100%;
	border-radius: 0;
	background-color: ${(props) => (props.hasError ? "#FFD8D9" : "#F4F7FE")};
	border-bottom-style: solid;
	border-bottom-width: ${(props) => (props.hasError ? "2px" : "1px")};
	border-bottom-color: ${(props) => {
		if (props.hasError) {
			return "#E82A4D";
		}
		if (props.disabled) {
			return "rgba(83,86,90,0.4)";
		} else {
			return "#768692";
		}
	}};
	position: relative;
	border-radius: 4px 4px 0 0;
	textarea {
		padding-top: ${(props) => (props.small ? "4px" : "14px")};
	}
`;

const ItemInput = styled.textarea`
	border: none;
	outline: none;
	padding: 14px 4px 4px;
	background: transparent;
	resize: vertical;
	width: 100%;
	min-height: 150px;
	z-index: 2;
	font-size: 14px;
	font-family: 'Poppins';
	font-weight: 300;
	color: #707372;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:disabled {
		& ~ .actionButton {
			display: none;
		}
	}
`;

const Label = styled.span`
	color: ${({disabled, hasFocus}) => (disabled ? "rgba(83,86,90,0.5)" : hasFocus ? "#2B3674" : "#A3AED0")};
	font-size: ${({ hasFocus }) => (hasFocus ? "11px" : "14px")};
	padding: ${({ hasFocus }) => (hasFocus ? "2px 4px" : "12px 12px")};
	line-height: 1;
	background: transparent;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	text-align: left;
	z-index: 2;
	transition: font-size 150ms ease-in-out, padding 100ms ease-in-out;
	pointer-events: none;
`;

const ErrorMessage = styled.em`
	color: #e4002b;
	font-size: 10px;
	line-height: 1.2;
	position: absolute;
	right: 0;
	bottom: -15px;
`;

const IconFinger = styled.span`
position: absolute;
right: 0.5rem;
top: 10px;
opacity: 0.7;
cursor: pointer;
z-index: 2;
`;

export { WrappInput, ItemInput, Label, ErrorMessage, IconFinger };
