import st from "styled-components"
import IconArrow from "@assets/icons/black.svg"
import IconArrow2 from "@assets/icons/black copy 8.svg"

const SelectContain = st.div`
  width: 100%;
  position: relative;
`

const Select = st.select`
  cursor: pointer;
  appearance: none;
  width: 100%;
  background: transparent;
  z-index: 1;
  position: relative;
  height: 40px;
  border-radius: 0;
  background-color:  ${props =>
    props.disabled ? "#F9FAFB" : "#F4F7FE"};
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => (props.disabled ? "#E0E0E0" : "#768692")};
  border-radius: 4px 4px 0 0;
  color: ${props => (props.disabled ? "#E0E0E0" : "#707372")};
  font-size: 14px;
  padding: 11px 4px 0px;
  outline: none;
  background-image: ${props =>
    props.disabled ? `url(${IconArrow2})` : `url(${IconArrow})`};
  background-repeat: no-repeat;
  background-position: center right 14px;
  }
  & ~ label{
    color: ${({disabled, hasFocus}) => (disabled ? "rgba(83,86,90,0.5)" : hasFocus ? "#2B3674" : "#A3AED0")};
    font-size: ${({ hasFocus }) => (hasFocus ? "11px" : "14px")};
    padding: ${({ hasFocus }) => (hasFocus ? "2px 4px" : "12px 12px")};
    line-height: 1;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: left;
    transition: font-size 150ms ease-in-out, padding 100ms ease-in-out;
    z-index: 1;
    pointer-events: none;
  }
  &:valid ~ label, &:disabled ~ label{
    font-size: 11px;
    padding: 2px 4px;
  }
`

export { SelectContain, Select }
