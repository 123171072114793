import { request } from "@services/api";
import endpoints from "@services/endpoints";

const getCategories = (token, uuid) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    const {
      BLOGS: { CATEGORIES },
    } = endpoints;
    request
      .post(CATEGORIES, { uuid })
      .then(({ data }) => resolve(data.data))
      .catch(() => reject({ error: "no existen categorias" }));
  });

export default getCategories;
