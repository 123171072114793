import st, { css } from "styled-components";

const ligthCss = css`
    background-color: #ECF0F1;
    color: #53565A;
`;

const pillCSS = css`
	background-color: transparent;
	&:hover { background-color: #f8f8f8; }
`

const primaryCSS = css`
    background-color: #0066FF;
    padding: 0 1rem;
    color: white;
    box-shadow: 0 2px 4px 0 rgba(83, 86, 90, 0.35);

    &:hover {
        background-color: #252323;
    }
`;

const Base = st.button`
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: ${({ square }) => (square ? 5 : 50)}px;
    border: 2px solid transparent;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    width: ${ ({small}) => small ? 30 : 40 }px;
    height: ${ ({small}) => small ? 30 : 40 }px;
    min-width: ${ ({small}) => small ? 30 : 40 }px;
    min-height: ${ ({small}) => small ? 30 : 40 }px;
    text-align: center;
    transition: background 200ms ease-in-out;

    svg {
        display: inline-block;
        vertical-align: middle;
    }

    &:disabled,
    &:disabled:hover {
				box-shadow: none;
        background: transparent;
        border: 2px solid #e0e0e0;
        color: #e0e0e0;
        cursor: not-allowed;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: #f5f5f5;
        border: 2px solid transparent;
        color: white;
    }

    ${({ ligth }) => ligth ? ligthCss : ''}
    ${({ primary }) => primary ? primaryCSS : ''}
    ${({ pill }) => pill ? pillCSS : ''}
`;

export { Base };
