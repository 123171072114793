import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  AFILIADOS: { UPDATE_ACCESOS },
} = endpoints;

const actualizarAccesos = ({ unique_id, email, passwd, passwdC }, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    request
      .post(UPDATE_ACCESOS, {
        unique_id,
        email,
        passwd,
        passwdC,
      })
      .then(() => {
        resolve(true);
      })
      .catch(err => reject(false));
  });

export default actualizarAccesos;
