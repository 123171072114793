import { request } from "@services/api";
import { Logout } from "@services";
import { CONTEXT } from "@constants/config";
import endpoints from "@services/endpoints";
import { navigate } from "gatsby";

const checkJWToken = (token) =>
	new Promise((resolve, reject) => {
		const { VALIDATE_TOKEN } = endpoints;
		const {
			auth: { email },
		} = JSON.parse(sessionStorage[CONTEXT]);

		request
			.get(VALIDATE_TOKEN)
			.then(() => resolve(true))
			.catch(({ response }) => {
				Logout({ email, token }).then(() => {
					reject(response);
					if (response.status === 420) {
						setTimeout(() => {
							navigate("/login?expiredToken=true");
						}, 2000);
					}
				});
			});
	});

	export default checkJWToken
