import { request } from "@services/api";
import endpoints from "@services/endpoints";
import { checkJWToken } from "@services";

const {
  ADMIN: { SET_CLIENTE },
} = endpoints;

const convertirCliente = (id, data, token) =>
  new Promise((resolve, reject) => {
		if(!+id) {
			reject({message: 'invalid id prospecto'})
		}

    request.setAuthorization(token);
    checkJWToken(token)
      .then(() => {
        request
          .post(`${SET_CLIENTE}/${id}`, data)
          .then(() => resolve(true))
          .catch(err => reject(err));
      })
      .catch(({ response }) => reject(response));
  });

export default convertirCliente;
