import React from "react";
import {
  Portal,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
} from "./styles";

import { Flex, Col, Svg, Text, Button } from "@components";
import iconAlert from "@assets/icons/warning.svg";
import success from "@assets/icons/success.svg";

export default ({
  show,
  width,
  onClose,
  children,
  icon,
  iconless,
  type,
  title,
  titleless,
  isCancel,
  className,
  unClosed,
  ...props
}) => {
  return (
    <Portal show={show}>
      {show && (
        <Modal width={width} {...props}>
          <ModalHeader>
            {!iconless && (
              <Flex justify="center" align="center" className="mb:15">
                <Svg
                  icon={icon ? icon : type === "success" ? success : iconAlert}
                  width={60}
                  height={60}
                />
              </Flex>
            )}
            {!titleless && (
              <Text fs={22} align="center">
                {title || "Oh no!"}
              </Text>
            )}
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
          {!unClosed && (
            <ModalFooter>
              <Flex justify="center" align="center">
                {isCancel && (
                  <Col autofit className="ph:05">
                    <Button square secondary onClick={() => onClose()}>
                      Cancelar
                    </Button>
                  </Col>
                )}

                <Col autofit className="ph:05">
                  <Button square primary onClick={() => onClose()}>
                    Aceptar
                  </Button>
                </Col>
              </Flex>
            </ModalFooter>
          )}
        </Modal>
      )}
    </Portal>
  );
};
