import React, { useEffect } from "react";
import { ModalTimer } from "@components";
import useInactive from "./hooks";
import { checkJWToken } from "@services";

export default ({ children }) => {
	const {
		token,
		clearState,
		inactive,
		setInactive,
		resetTimer,
		modalTerminarSesion,
		setModalTerminarSesion,
		initModalTimer,
		setInitModalTimer,
	} = useInactive();

	useEffect(() => {
		setInterval(() => {
			checkJWToken(token).catch(() => {
				if (typeof window !== "undefined") {
					clearState();
					window.location.href = "login?expiredToken=true";
				}
			});
		}, 300000);
	}, []);

	return (
		<React.Fragment>
			{children}
			{inactive && (
				<ModalTimer
					initTimer={initModalTimer}
					show={modalTerminarSesion}
					onClose={() => {
						setModalTerminarSesion(false);
						setInitModalTimer(false);
						setInactive(false);
						resetTimer();
					}}
				/>
			)}
		</React.Fragment>
	);
};
