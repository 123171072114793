import { useState, useEffect, useContext } from "react";
import { Temporizer } from "@utils";
import AdminContext from "@context";
import { navigate } from "gatsby";
import { Logout } from "@services";

const LIMIT_MINUTES = 12;
let newinterval = null;

const useInactive = () => {
  const {
    auth: { isAuthenticated, token, email },
    clearState,
  } = useContext(AdminContext);

  const [inactive, setInactive] = useState(false);
  const [modalTerminarSesion, setModalTerminarSesion] = useState(false);
  const [initModalTimer, setInitModalTimer] = useState(false);

  useEffect(() => {
    if (inactive) {
      sessionStorage.regressiveCount = new Date().getTime();
      setModalTerminarSesion(true);
      setInitModalTimer(true);
    } else {
      watchEvents();
    }
  }, [inactive]);

  useEffect(() => {
    if (isAuthenticated) {
      resetTimer();
      watchEvents();
    } else {
      unwatchEvents();
    }
  }, []);

  const checkInactive = () => {
    const { minutes, seconds } = Temporizer("lastActivity");
    // if (minutes) {
    // 	console.log(
    // 		`%c[ Inactivo ${minutes} minuto ${seconds} segundos ]`,
    // 		"color: #ff3434"
    // 	);
    // } else {
    // 	console.log(`%c[ Inactivo ${seconds} segundos ]`, "color: #ff3434");
    // }
    if (minutes >= LIMIT_MINUTES) {
      clearTimeout(newinterval);
      setInactive(true);
      unwatchEvents();
    }
  };

  const resetTimer = () => {
    clearTimeout(newinterval);
    if (isAuthenticated) {
      sessionStorage.lastActivity = new Date().getTime();
      newinterval = setInterval(checkInactive, 10000);
      // console.log(`%c[ Usuario Activo ]`, "color: #00e676");
    }
  };

  const unwatchEvents = () => {
    if (typeof window !== "undefined") {
      window.onload = null;
      document.onload = null;
      document.onmousemove = null;
      document.onmousedown = null;
      document.ontouchstart = null;
      document.onclick = null;
      document.onkeypress = null;
      document.removeEventListener("scroll", null, true);
      document.removeEventListener("popstate", null, true);
      document.removeEventListener("visibilitychange", null, true);
    }
  };

  const watchEvents = () => {
    if (inactive || !isAuthenticated) return;
    if (typeof window !== "undefined") {
      window.onload = resetTimer;
      document.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer;
      document.ontouchstart = resetTimer;
      document.onclick = resetTimer;
      document.onkeypress = resetTimer;
      document.addEventListener("scroll", resetTimer, true);
      document.addEventListener("popstate", resetTimer, true);
      document.addEventListener("visibilitychange", watchVisibility, true);
    }
  };

  const watchVisibility = () => {
    if (document.visibilityState === "visible") {
      if (!!+sessionStorage.regressiveCount) {
        console.log(Temporizer("regressiveCount"));
        const { hours, minutes } = Temporizer("regressiveCount");

        if (hours > 0 || minutes > LIMIT_MINUTES + 3) {
          setModalTerminarSesion(false);
          setInitModalTimer(false);

          Logout({ email, token }).then(() => {
            if (typeof window !== "undefined") {
              window.location.href = `${window.location.origin}/login`;
            }
          });
        }
      }
    }
  };

  return {
    token,
    clearState,
    inactive,
    setInactive,
    resetTimer,
    modalTerminarSesion,
    setModalTerminarSesion,
    initModalTimer,
    setInitModalTimer,
  };
};

export default useInactive;
