import { request } from "@services/api";
import endpoints from "@services/endpoints";

import { checkJWToken } from "@services";

//category es el nombre de la tabla en la base de datos
const AddOption = ({ category, label, description, token }) =>
	new Promise(async (resolve, reject) => {
		request.setAuthorization(token);
		const URL = await endpoints.BLOGS[category.toUpperCase()];

		checkJWToken(token)
			.then(() => {
				request
					.post(`${URL}/save`, {
						label,
						description,
					})
					.then(({ data }) => {
						resolve(data.data);
					})
					.catch(({ response: { data } }) => reject(data));
			})
			.catch(({ response }) => reject(response));
	});

	export default AddOption
