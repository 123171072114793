import React, { useContext } from "react";
import Item from "@layout/item";
import { Text, Box, Svg } from "@components";
import { SidebarWrap, SideBarContent, Divider } from "./styles";
import { navigate } from 'gatsby'

import AdminContext from "@context";
import { Logout } from "@services";

import logout from "@assets/icons/logout.svg";

const Sidebar = ({ items }) => {
	const {
		auth: { username, email, token },
		clearState,
	} = useContext(AdminContext);

	const onCloseSession = () => {
		Logout({ email, token }).then(() => {
			clearState();
			navigate("/login");
		});
	};

	return (
		<SidebarWrap>
			<SideBarContent>

				<Text fs={31} className="ph:2 pt:2">
					<strong>CRM</strong> Digital
				</Text>
				<Text fs={12} className="ph:2 pb:4 pt:05" color='#A3AED0'>
					{email}
				</Text>

				{!!items?.length &&
					items.map((el, index) => (
						<Item
							key={index.toString()}
							icon={el.icon}
							label={el.label}
							width={el.w}
							link={el.link}
						/>
					))}

				<Divider className="mb:2" />

				<Item
					icon={logout}
					label="Cerrar sesión"
					width={90}
					onClick={() => onCloseSession()}
					link={undefined}
				/>
			</SideBarContent>

			<Box className='mt:1 news'>
				<Text fs={26} align="center" className="mb:1">Beta 1.0</Text>
				<Text fs={12} fw={300} align="center">Esta es una plataforma desarrollada bajo metodologías ágiles, esto quiere decir que recibirás actualizaciones de valor de manera continúa.</Text>
			</Box>
		</SidebarWrap>
	);
};

export default Sidebar;
