
import st, { css } from 'styled-components';
import { prop } from 'styled-tools';
import { breakpoints } from '@constants/css';

const Icon = css`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${({icon}) => icon});
`;

const since = (bp, size) => {
    const [w,h] = size.split(':');
    return css`
        @media (min-width: ${breakpoints[bp]}px) {
            width: ${w}px;
            min-width: ${w}px;
            min-height: ${h}px;
            height: ${h}px;
        }
    `
}

const Graphic = st.div`
    ${Icon}
    width: ${prop('width', 0)}px;
    min-width: ${prop('width', 0)}px;
    min-height: ${prop('height', 0)}px;
    height: ${prop('height', 0)}px;
    object-fit: contain;

    ${ ({xs}) => xs ? since('xs', xs) : '' } /* Ej: 320:260 */
    ${ ({sm}) => sm ? since('sm', sm) : '' }
    ${ ({md}) => md ? since('md', md) : '' }
    ${ ({lg}) => lg ? since('lg', lg) : '' }
    ${ ({hd}) => hd ? since('hd', lg) : '' }

`;

export {
    Graphic
}
