import React from "react";
import { Select, SelectContain } from "./styles";

const change = e => {
  return e.target.value;
};

const customSelect = ({
  getValue,
  placeholder,
  options,
  value,
  id,
  disabled,
  defaultValue,
  otherLess,
}) => {
  return (
    <SelectContain>
      <Select
        value={value}
        onChange={e => getValue(change(e))}
        disabled={disabled}
        required
        id={id}
        defaultValue={defaultValue ? defaultValue : ""}
      >
        <option selected value=""></option>
        {options &&
          options.map((option, key) => (
            <option key={key} value={option.id}>
              {option.label}
            </option>
          ))}
        {!otherLess && <option value="9999">Otro</option>}
      </Select>
      <label htmlFor={id}>{placeholder}</label>
    </SelectContain>
  );
};

export default customSelect;
