import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  BLOGS: { ADD_BLOG },
} = endpoints;

const AddProperty = (blog, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
		request.setContent("multipart/form-data");

		const formData = new FormData();

		for(let prop in blog) {
			formData.append(prop, blog[prop]);
		}

    const response = request.post(ADD_BLOG, formData);
    response
      .then(({ data }) => resolve(data))
      .catch(() => reject({ error: "no se pudo guardar" }));
  });

export default AddProperty;
