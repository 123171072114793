const checkZipcode = postal =>
  new Promise((resolve, reject) => {
    // const URL = `https://sepomex.razektheone.com/codigo_postal?cp=${zipcode}`;
    const token = "3e7b82cb-143c-46a0-be46-831f71bf6a08";
    const URL = `https://api.copomex.com/query/info_cp/${postal}?token=${token}`;

    fetch(URL)
      .then(r => r.json())
      .then(r => {
        let data = {
          postal,
          country: null,
          state: null,
          city: null,
          localty: null,
          colonias: [{ id: 0, label: "" }],
        };

        const colonias = r.map(({ error, response }, index) => {
          if (error) {
            reject(error);
            return;
          }

          data = {
            ...data,
            country: response.pais,
            state: response.estado,
            city: response.ciudad
          };

          return {
            id: index + 1,
            label: response.asentamiento,
          };
        });

        data = {
          ...data,
          colonias,
        };

        resolve(data);
      })
      .catch(err => reject(err));
  });

export default checkZipcode;
