// Initialize the agent at application startup.
const fingerprint = new Promise((resolve, reject) => {
  if (typeof document !== "undefined") {
    const script = document.createElement("script");
    script.onload = resolve;
    script.onerror = () => reject("Failed to load the script");
    script.async = true;
    script.src =
      "https://cdn.jsdelivr.net/npm/" +
      "@fingerprintjs/fingerprintjs@3/dist/fp.min.js";
    document.head.appendChild(script);
  }
}).then(() => FingerprintJS.load());

export default fingerprint;
