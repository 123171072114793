import { request } from "@services/api";
import endpoints from "@services/endpoints";

const activarCuenta = (token) =>
	new Promise((resolve, reject) => {
		const { ACTIVAR_CUENTA } = endpoints;
		const response = request.post(ACTIVAR_CUENTA, { token });

		if (typeof window !== "undefined") {
			window.history.pushState({}, "CRM Digital Booting", window.location.origin);
		}

		response
			.then(({ data }) => resolve(data.email))
			.catch(({ response }) => reject({ error: response.data.message }));
	});

export default activarCuenta;
