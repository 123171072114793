import React, { useRef, useState, useEffect } from "react";
import { Uploader, File, Title, InfoMessage } from "./styles";
import {
  Flex,
  Col,
  Button,
  Text,
  ButtonIcon,
  Box,
  Publish,
  UnPublish,
  Viewer,
} from "@components";
import icon from "@assets/icons/files.svg";

export default ({
  name,
  id,
  multiple,
  limit = 5,
  typeSize = "MB",
  type = "img",
  getValue,
  labelSize,
  title,
  getErrorSize,
  children,
}) => {
  const _label = useRef(null);
  const [files, setFiles] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [errorLimitFiles, setErrorLimitFiles] = useState(false);
  const [currentPreview, setCurrentPreview] = useState(0);
  const [acumultiveSize, setAcumultiveSize] = useState(0);
  const formats =
    type === "video"
      ? "video/mp4, video/avi"
      : "image/png,image/jpeg,image/jpg,image/webp";

  useEffect(() => {
    getValue && getValue(multiple ? files : files[0]);
  }, [files]);

  useEffect(() => {
    if (acumultiveSize.size > 250) {
      getErrorSize && getErrorSize(true);
    } else {
      getErrorSize && getErrorSize(false);
    }
  }, [acumultiveSize]);

  const handleChange = ({ files }) => {
    const _files = [];
    let totalSize = 0;

    for (let f in files) {
      if (typeof files[f] === "object") {
        totalSize += files[f].size;
        files[f].fileSize = fileSize(files[f].size);
        files[f].validate = validateFile(files[f]);

        files[f].urlObject = URL.createObjectURL(files[f]);
        _files.push(files[f]);
      }
    }
    const filterFiles = reducerDFiles(_files);
    const tSize = fileSize(totalSize);
    setAcumultiveSize(tSize);

    if (tSize.size < 250) {
      if (multiple) {
        if (filterFiles.length > 20) {
          setFiles(filterFiles.slice(0, 20));
          setErrorLimitFiles(true);
        } else {
          setFiles(filterFiles);
          setErrorLimitFiles(true);
        }
      } else {
        console.log(tSize);
        if (tSize.unit === "KB") {
          setFiles(filterFiles);
        } else {
          setFiles(filterFiles);
          if (tSize.size > limit) {
            setAcumultiveSize({ size: 500 });
          }
        }
      }
    } else {
      setFiles([]);
    }
  };

  const handleSelectFiles = () => {
    _label.current.click();
  };

  const handleDelete = key => {
    const _files = files.filter((item, index) => index !== key);
    setFiles([..._files]);
  };

  const handleShowPreview = index => {
    setCurrentPreview(index);
    setShowPreview(true);
  };

  //drag events
  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer;
    if (!!files.files.length) {
      handleChange(files);
    }
  };

  const validateFile = file => {
    const validTypes =
      type === "video"
        ? ["video/mp4", "video/avi"]
        : ["image/jpeg", "image/jpg", "image/png", "image/webp"];
    if (validTypes.includes(file.type)) {
      return file.fileSize.unit === typeSize
        ? file.fileSize.size < limit
        : true;
    }
    return false;
  };

  const fileSize = size => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));

    const _size = parseFloat((size / Math.pow(k, i)).toFixed(2));
    return {
      size: _size,
      formatedSize: `${_size} ${sizes[i]}`,
      unit: sizes[i],
    };
  };

  const reducerDFiles = files => {
    //remove files duplicated
    let _files = [];
    if (files.length) {
      let filteredArray = files.reduce((file, current) => {
        const x = file.find(item => item.name === current.name);
        if (!x) {
          return file.concat([current]);
        } else {
          return file;
        }
      }, []);

      _files = [...filteredArray];
    }

    return _files;
  };

  return (
    <React.Fragment>
      <Uploader
        name={name}
        htmlFor={id}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        className="mb:3"
      >
        <File
          id={id}
          type="file"
          accept={formats}
          multiple={multiple}
          ref={_label}
          onChange={({ target }) => handleChange(target)}
        />
        <Title>{title || "Archivo(s)"}</Title>
        {labelSize && (
          <Text fs={14} fw={500} align="center">
            {labelSize}
          </Text>
        )}
        <Text fs={14} fw={500} align="center">
          Arrastra y suelta{" "}
          {type === "video"
            ? multiple
              ? "tus videos"
              : "tu video"
            : multiple
            ? "tus imagenes"
            : "tu imagen"}{" "}
          aquí o
        </Text>
        <Flex justify="center" className="pt:1">
          <Col autofit>
            <Button
              square
              primary
              leftIcon={icon}
              onClick={() => handleSelectFiles()}
            >
              Seleccionar{" "}
              {type === "video"
                ? multiple
                  ? "videos"
                  : "video"
                : multiple
                ? "imagenes"
                : "imagen"}
            </Button>
          </Col>
        </Flex>
      </Uploader>

      {children}

      {errorLimitFiles && (
        <InfoMessage>
          Estas intentando cargar más de 20 archivos al mismo tiempo, el resto
          de los archivos han sido ignorados, porfavor carga de 20 en 20.
        </InfoMessage>
      )}

      {!!files.length &&
        files.map(({ name, type, fileSize, validate }, id) => (
          <Box key={id} inline className="mb:05" padding="0.5rem 1rem">
            <Flex wrapper align="center">
              <Col autofit className="pr:1">
                <Text fw={500} fs={14}>
                  {id + 1}
                </Text>
                <Text fs={10} opacity={0.2}>
                  ID
                </Text>
              </Col>
              <Col xs={7}>
                <Text fw={500} fs={14}>
                  {name.length > 25
                    ? name.substring(0, 25) +
                      "..." +
                      type.replace(/image\//g, "")
                    : name}
                </Text>
                <Text fs={10} opacity={0.2}>
                  {type}
                </Text>
              </Col>

              <Col xs={7}>
                <Text fw={500} fs={14}>
                  {fileSize.formatedSize}
                </Text>
                <Text fs={10} opacity={0.2}>
                  Tamaño
                </Text>
              </Col>

              {validate ? (
                <Col autofit className="ph:05">
                  <Publish w={110} label="Archivo válido" />
                </Col>
              ) : (
                <Col autofit className="ph:05">
                  <UnPublish w={120} label="Archivo inválido" />
                </Col>
              )}

              <Col autofit className="t:center">
                <ButtonIcon
                  onClick={() => handleShowPreview(id)}
                  tooltip="Ver archivo"
                  w={90}
                  direction="bottom"
                  pill
                  icon="show"
                />
              </Col>

              <Col autofit className="t:center">
                <ButtonIcon
                  onClick={() => handleDelete(id)}
                  tooltip="Eliminar"
                  w={80}
                  direction="bottom"
                  pill
                  icon="delete"
                />
              </Col>
            </Flex>
          </Box>
        ))}

      <Viewer
        files={files}
        show={showPreview}
        currentImg={currentPreview}
        onClose={() => setShowPreview(false)}
      />
    </React.Fragment>
  );
};
