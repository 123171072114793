import { useEffect, useContext } from "react";
import AdminContext from "@context";
import { CONTEXT, MENUS } from "@constants";
import { navigate } from "gatsby";

const useMiddleware = () => {
  const {
    auth: { isAuthenticated, role },
		requestLoading,
		requestText,
		notify,
		setNotify
  } = useContext(AdminContext);

  const menu = MENUS[role];

  useEffect(() => {
    !isAuthenticated && navigate("/login");
    let validateStorage = () => {};

    if (typeof window !== "undefined") {
      validateStorage = event => {
        if (event.storageArea === sessionStorage) {
          if (!!!sessionStorage[CONTEXT]) {
            window.location.href = `${window.location.origin}/login`;
          }
        }
      };
      window.addEventListener("storage", validateStorage, true);
    }
    return () => window.removeEventListener("storage", validateStorage, true);
  }, []);

  return {
    menu,
    isAuthenticated,
		requestLoading,
		requestText,
		notify,
		setNotify
  };
};

export default useMiddleware;
