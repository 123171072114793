import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  AFILIADOS: { GET_PERFIL },
} = endpoints;

const getPerfil = (unique_id, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    request
      .post(GET_PERFIL, { unique_id })
      .then(({data: { data }}) => {
        resolve(data);
      })
      .catch(err => reject(err));
  });

export default getPerfil;
