import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  AFILIADOS: { SAVE_PERFIL },
} = endpoints;

const guardarPerfil = (perfil, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);
    request
      .post(SAVE_PERFIL, perfil)
      .then(({data: { data }}) => {
        resolve(data);
      })
      .catch(err => reject(err));
  });

export default guardarPerfil;
