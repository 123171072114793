import React from "react";
import styled, {keyframes} from "styled-components";

const spin = keyframes`
	from {
		transform: rotate(270deg);
	}
	to{
		transform: rotate(630deg);
	}
`;

const size = 40;
const Spinner = styled.div`
	display: inline-block;
	width: ${({ w }) => w || size}px;
	min-width: ${({ w }) => w || size}px;
	min-height: ${({ w }) => w || size}px;
	height: ${({ w }) => w || size}px;
	border-radius: 100%;

	border: 2px solid rgba(0, 0, 0, 0.4);
	border-left-color: white;
	animation: ${spin} 1s linear infinite;
`;

export default ({ ...props }) => (<Spinner {...props}></Spinner>);
