import React, { useState, useEffect } from "react";

import { Svg } from "@components";
import { HeaderWrapper, Control, Select } from "./styles";

import { MESES } from "@constants";

import arrow from "@assets/icons/arrow-down.svg";

const Header = ({ month, year, updateMonth, updateYear }) => {
	const [anios, setAnios] = useState([]);

	useEffect(() => {
		const y = parseInt(new Date().getFullYear());
		const init = y - 50;
		const limit = y + 50;

		const currentYear = year || y;

		let a = [];
		let j = 0;
		for (let i = init; i < limit; i++) {
			a.push(
				<option key={j} selected={currentYear === i} value={i}>
					{i}
				</option>
			);
			j++;
		}

		setAnios(a);
	}, []);

	const handleMonth = (e) => {
		const month = +e.target.value;
		updateMonth && updateMonth(month);
	};

	const handleYear = (e) => {
		const year = +e.target.value;
		updateYear && updateYear(year);
	};

	return (
		<HeaderWrapper>
			<span className="ph:05">
				<Control>
					<Select placeholder="Mes" onChange={handleMonth}>
						{MESES?.length &&
							MESES.map((mes, index) => (
								<option
									key={index}
									selected={mes?.value === month}
									value={mes?.value}
								>
									{mes?.label}
								</option>
							))}
					</Select>
					<Svg icon={arrow} w={18} />
				</Control>
			</span>
			<span className="ph:05">
				<Control>
					<Select placeholder="Año" onChange={handleYear}>
						{anios?.length && anios.map((a) => a)}
					</Select>
					<Svg icon={arrow} w={18} />
				</Control>
			</span>
		</HeaderWrapper>
	);
};

export default Header;
