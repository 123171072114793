import React, { useState, useEffect } from "react";
import { Flex, Col, ButtonIcon, Text, Svg } from "@components";
import { Portal, PortalHeader, PortalButton } from "./styles";

import aleft from "@assets/icons/aleft.svg";
import aright from "@assets/icons/aright.svg";

export default ({ show, files, currentImg, onClose }) => {
	const [active, setActive] = useState(0);
	const [limit, setLimit] = useState(0);

	const next = () => {
		const c = active + 1;
		setActive(c < limit ? c : limit);
	};

	const prev = () => {
		const c = active - 1 > 0 ? active - 1 : 0;
		setActive(c);
	};

	const handleClose = () => {
		setActive(0);
		onClose();
	};

	useEffect(() => {
		setLimit(files.length - 1);
	}, [files]);

	useEffect(() => {
		setActive(currentImg);
	}, [currentImg]);

	return (
		<Portal show={show} current={active}>
			<PortalHeader>
				<Flex align="center" justify="between">
					<Col autofit>
						<Text fs={18} fw={500}>
							{files.length || 0} Archivos cargados
						</Text>
					</Col>
					<Col autofit>
						<ButtonIcon
							small
							onClick={() => handleClose()}
							tooltip="Cerrar"
							direction="bottom"
							icon="close"
							w={70}
							ligth
						/>
					</Col>
				</Flex>
			</PortalHeader>

			<Flex align="center" justify="center" wrapper>
				{active > 0 && (
					<PortalButton onClick={() => prev()}>
						<Svg icon={aleft} width={40} height={40} />
					</PortalButton>
				)}

				<Col xs={10} md={10}>
					<Flex align="center" style={{ height: "90vh" }}>
						<Flex
							id="slide"
							wrapper
							style={{
								width: `${files.length}00%`,
								marginLeft: `-${active}00%`,
							}}
						>
							{!!files.length &&
								files.map(({ name, urlObject, fileSize, type }, index) => (
									<Flex
										key={index}
										justify="center"
										align="center"
										className="p:2 slide_item"
										style={{
											minWidth: "100%",
											opacity: active === index ? 1 : 0.5,
											transform: active === index ? "scale(1)" : "scale(0.7)",
										}}
									>
										{["video/mp4", "video/avi"].includes(type) && (
											<video
												style={{
													height: "450px",
													maxHeight: "450px",
													objectFit: "contain",
												}}
												controls
											>
												<source src={urlObject} type={type} />
											</video>
										)}

										{["image/jpeg", "image/jpg", "image/png"].includes(
											type
										) && (
											<img
												src={urlObject}
												style={{
													height: "450px",
													maxHeight: "450px",
													objectFit: "contain",
												}}
											/>
										)}

										<Text fs={14} fw={500} align="center" className="p:1">
											{name} - {fileSize.formatedSize}
										</Text>
									</Flex>
								))}
						</Flex>
					</Flex>
				</Col>

				{active !== limit && (
					<PortalButton onClick={() => next()}>
						<Svg icon={aright} width={40} height={40} />
					</PortalButton>
				)}
			</Flex>
		</Portal>
	);
};
