import React from "react";
import st from "styled-components";

const Box = st.div`
	display: block;
	width: 100%;
	background-color: ${ ({primary}) => primary ? '#0066ff' : '#fff' };
	color: ${ ({primary}) => primary ? 'white' : 'black' };
	padding: ${ ({padding}) => padding ? padding : '1.5rem'};
	border: 1px solid transparent;

	${({ inline }) =>
		!inline
			? "box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);"
			: "border-color: #e3e3e3;"}

	border-radius: 10px;
`;

const BoxComponent = ({ children, ...props }) => (<Box {...props}>{children}</Box>)
export default BoxComponent
