import styled, { css } from "styled-components";
import { breakpoints } from '@constants/css';

const WrappTooltip = styled.span`
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:hover .content {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipContent = styled.span`
  display:inline-block;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  min-width: ${ ({w}) => w }px;
  width: ${ ({w}) => w }px;
  position: absolute;
  background-color: #11047A;
  z-index: 5;
  cursor: initial;
  text-align: left;
  border-radius: 4px;
  visibility: hidden;
  transition: opacity ${ ({delay}) => delay }ms linear;
  pointer-events: none;
  opacity: 0;

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    position: absolute;
    pointer-events: none;
  }

  ${({ direction }) => {
    return  direction === 'top' ? cssTop :
            direction === 'left' ? cssLeft :
            direction === 'bottom' ? cssBottom :
            direction === 'right' ? cssRight : ''
  }}
`;

const cssTop = css`
  bottom: calc(100% + 10px);

  &, &:before {
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    top: 100%;
    border-top-color: #11047A;
  }
`;

const cssBottom = css`
  top: calc(100% + 10px);

  &, &:before {
    @media screen and (min-width:250px) {
      right: 1rem;
      transform: translateX(10%);
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:before {
    bottom: 100%;
    border-bottom-color: #11047A;
  }
`;

const cssLeft = css`
  right: calc(100% + 10px);

  &, &:before {
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 100%;
    border-left-color: #232525;
  }
`;

const cssRight = css`
  left: calc(100% + 10px);

  &, &:before {
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    right: 100%;
    border-right-color: #11047A;
  }
`;

export { WrappTooltip, TooltipContent };
