import React from "react";
import { Svg, Center, Text, Tooltip } from "@components";
import unpublish from "@assets/icons/alert.svg";

export default ({ children, label, w, ...props }) => {
	return (
		<Center>
			<Tooltip
				w={w ? w : 100}
				direction="bottom"
				element={<Svg icon={unpublish} width={20} height={20} />}
			>
				<Text fs={10}>{label || 'No Publicada'}</Text>
			</Tooltip>
		</Center>
	);
};
