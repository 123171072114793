import React from "react";
import { Modal, Text } from "@components";
import { Timer } from "./styles"
import useCaducidad from "./hooks";

import secureAlert from "@assets/icons/secure-alert.svg";

export default ({ show, onClose, initTimer }) => {
	const { initTime, handleClose, mins, secs } = useCaducidad(
		onClose,
		initTimer,
		show
	);

	return (
		<Modal
			width={500}
			show={show}
			onClose={() => handleClose()}
			icon={secureAlert}
			title="Hola, ¿sigues por aquí?"
		>
			<div className="ph:2 pt:2">
				<Text fs={14} fw={500} line={1.4}>
					Notamos que has estado inactivo, por seguridad si no registramos
					actividad de tu parte la sesión se cerrará en
				</Text>
			</div>

			<Timer>
				{initTime ? (
					<Text xs={38} fs={42} fw={500} align="center">
						0{mins}:{secs < 10 ? "0" + secs : secs}
					</Text>
				) : (
					<Text xs={38} fs={42} fw={500} align="center">
						02:59
					</Text>
				)}
			</Timer>

		</Modal>
	);
};
