import React from 'react'
import st from 'styled-components'
import { switchProp } from "styled-tools";

const Theme = switchProp('theme', {
	light: '#fdfdfd',
	dark: '#232525'
})

const ThemeColor = switchProp('theme', {
	dark: '#fdfdfd',
	light: '#232525'
})

const Main = st.main`
	width: 100%;
	min-height: 100%;
	display: block;
	background-color: ${ ({theme}) => theme ? Theme : white };
	color: ${ ({theme}) => theme ? ThemeColor : white };
`

export default ({children, ...props}) => {
	return (
		<Main {...props}>{children}</Main>
	)
}
