import { request } from "@services/api";
import endpoints from "@services/endpoints";
const {
	BLOGS
} = endpoints;

const types = {
	0: 'UPLOAD_FILES',
	1: 'UPLOAD_PLANOS',
	2: 'UPLOAD_VIDEOS',
};

const uploadFiles = (propiedadId, files, type, token) =>
	new Promise(async (resolve, reject) => {
		request.setAuthorization(token);
		request.setContent("multipart/form-data");

		if (files.length > 20) {
			let filesParts = getFilesByParts(files);

			const resolvers = await filesParts.map((files) =>
				upload({
					id: propiedadId,
					files,
					type,
				})
			);

			Promise.all(resolvers)
				.then(() => resolve(true))
				.catch(() => reject(false));
		} else {
			upload({
				id: propiedadId,
				files,
				type,
			})
				.then(() => resolve(true))
				.catch(() => reject(false));
		}
	});

const upload = ({ id, files, type }) =>
	new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append("id", id);

		const resolvers = files.map((file) => {
			formData.append("files[]", file);
		});

		setTimeout(
			async () =>
				await request
					.post(types[type], formData)
					.then(() => resolve(true))
					.catch(() => reject(false)),
			5000
		);
	});

const getFilesByParts = (files) => {
	let $array = [];
	const NEW_ARRAY_SIZE = 20;

	for (let i = 0; i < files.length; i += NEW_ARRAY_SIZE) {
		let elements = files.slice(i, i + NEW_ARRAY_SIZE);

		elements = elements.filter(
			(el) => el !== undefined && el !== null && typeof el === "object"
		);

		$array.push(elements);
	}

	return $array;
};


export default uploadFiles
