import React, { useState, useEffect } from "react";
import { Flex, Col, Input } from "@components";

import Header from "./header";
import CalendarGrid from "./calendar";

import { DIA_SEMANA, MESES } from "@constants";

import {
	Wrapper,
	Container,
	Overlay,
	Content,
	Aceptar,
	Cancelar,
} from "./styles";

const Calendar = ({ slash = "-", getValue, value, ...props }) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState("");

	const [month, setMonth] = useState(0);
	const [year, setYear] = useState(0);
	const [activeDay, setActiveDay] = useState(0);

	useEffect(() => {
		if (!value) {
			const date = new Date();

			const dia = date.getDate();
			const mes = date.getMonth();
			const anio = date.getFullYear();

			setActiveDay(dia);
			setMonth(mes);
			setYear(anio);
		}
	}, []);

	useEffect(() => {
		if (value) {
			const [y, m, d] = value.split(slash);
			setYear(+y);
			setMonth(+(m - 1));
			setActiveDay(+d);
			setData(value);
			return;
		}
	}, [value]);

	const onClose = () => {
		setShow(false);
	};

	const handleOnChange = () => {
		const result = new Date(year, month, activeDay);
		const diaSemana = result.getDay();

		const d = `0${activeDay}`;
		const m = `0${month + 1}`;

		const date = `${year}${slash}${+m > 9 ? +m : m}${slash}${+d > 9 ? +d : d}`;
		const reverse = `${+d > 9 ? +d : d}${slash}${
			+m > 9 ? +m : m
		}${slash}${year}`;

		const mes = MESES[month];
		const dayText = DIA_SEMANA[diaSemana];

		const largeDate = `${dayText} ${+d > 9 ? +d : d} de ${mes?.large}, ${year}`;
		const shortDate = `${dayText} ${+d > 9 ? +d : d}, ${mes?.label} ${year}`;

		const data = {
			year,
			month: +m > 9 ? +m : m,
			day: +d > 9 ? +d : d,
			date,
			reverse,
			dayText,
			largeDate,
			shortDate,
		};

		setData(date);
		getValue && getValue(data);
		onClose && onClose();
	};

	return (
		<Wrapper>
			<Input
				value={data}
				getValue={(v) => setData(v)}
				showCalendar={(v) => setShow(v)}
				type="calendar"
				{...props}
			/>

			<Container show={show}>
				<Overlay />
				<Content>
					<Header
						year={year}
						month={month}
						updateMonth={(v) => setMonth(v)}
						updateYear={(v) => setYear(v)}
					/>

					<CalendarGrid
						getValue={(v) => setActiveDay(v)}
						month={month}
						year={year}
						day={activeDay}
					/>

					<Flex align="center" justify="end">
						<Col autofit className="pr:05">
							<Cancelar onClick={() => onClose()}>Cancelar</Cancelar>
						</Col>
						<Col autofit>
							<Aceptar onClick={() => handleOnChange()}>Aceptar</Aceptar>
						</Col>
					</Flex>
				</Content>
			</Container>
		</Wrapper>
	);
};

export default Calendar;
