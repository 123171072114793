import st from 'styled-components'

const NavWrapper = st.div`
	display: flex;
	width: 100%;
	padding: 2rem 2rem 0.5rem 2rem;
	justify-content: space-between;
	z-index: 10;
	position: sticky;
	top: 0;

	background-color: #F4F7FE;
`;

const IconButton = st.button`
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
	border-radius: 40px;
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background-color: #E8EFFF;
	}
`;

const NavigationBar = st.div`
	display: inline-flex;
	background-color: white;
	border-radius: 40px;
	align-items: center;
	align-content: center;
	justify-content: end;
	padding: 0.25rem 0.5rem;
`

const NavSearch = st.div`
	min-width: 200px;
	width: 200px;
	display: inline-flex;

	div {
		border-radius: 40px;
		margin-right: 0.5rem
	}
`

export { NavWrapper, IconButton, NavigationBar,NavSearch }
