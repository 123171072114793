import React from "react";
import { Svg, Center, Text, Tooltip } from "@components";
import publish from "@assets/icons/success.svg";

export default ({ children, label, w, ...props }) => {
	return (
		<Center>
			<Tooltip
				w={w ? w : 85}
				direction="bottom"
				element={<Svg icon={publish} width={20} height={20} />}
			>
				<Text fs={10}>{label || 'Publicada'}</Text>
			</Tooltip>
		</Center>
	);
};
