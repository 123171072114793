import React, { useEffect, useState, useContext } from "react";
import { Flex, Loader, Text } from "@components";
import AdminContext from "@context";
import styled from "styled-components";

const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	z-index: 9999999;
	background-color: rgba(255, 255, 255, 0.8);
`;

const Loading = () => {
	const { requestText } = useContext(AdminContext);
	const [message, setMessage] = useState('Estamos procesando información')

	useEffect(() => {
		requestText && setMessage(requestText)
	}, [])

	return (
		<LoadingWrapper>
			<Flex align="center" justify="center">
				<Loader w={60} />
			</Flex>
			<Text align="center" opacity={0.8} fs={18} className="mt:2">
				Espere un momento...
			</Text>
			<Text align="center" opacity={0.5} fs={14} className="mb:4">
				{message}
			</Text>
		</LoadingWrapper>
	);
};

export default Loading;
