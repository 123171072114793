import st, { css } from "styled-components";

const Uploader = st.label`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	min-height: 200px;
	height: 200px;
	border: 1px dashed #aeaeae;
	border-radius: 5px;
	position: relative;
	margin-top: 1rem;
`;

const Title = st.span`
	display: inline-block;
	width: 100%;
	position: absolute;
	left: 0;
	top: -0.9rem;
	font-size: 10px;
	color: #888;
	font-family: 'Poppins';
	user-select: none;
`;

const File = st.input`
	display: none;
	opacity: 0;
`;

const message = css`
	border-radius: 5px;
	margin-bottom: 1rem;
	font-size: 12px;
	padding: 0.5rem 1rem;
	text-align: center;
	line-height: 1.5;
	display: block;
`;

const InfoMessage = st.span`
	${message}
	background-color: rgba(0, 0, 255, 0.1);
	border: 1px solid rgba(0, 0, 255, 0.1);
	color: blue;
`;

const ErrorMessage = st.span`
	${message}
	background-color: rgba(255, 0, 0, 0.1);
	border: 1px solid rgba(255, 0, 0, 0.1);
	color: red;
`;

export { Uploader, File, Title, InfoMessage, ErrorMessage };
