import React from "react";
import { Text, Separator } from "@components";

export default ({ children, ...props }) => (
  <>
    <Text xs={12} md={14} color="#0066FF" fw={400} {...props}>
      {children}
    </Text>
    <Separator className="mt:05" />
  </>
);
