import React, { useRef } from "react";
import { Base, Text } from "./styles";
import { Loader } from "@components";
import { Svg } from "@components"

export default ({
	children,
	leftIcon,
	rightIcon,
	forwardRef,
	mobile,
	loading,
	...props
}) => {
	return (
		<Base {...props} ref={forwardRef} mobile={mobile}>
			{leftIcon && <Svg icon={leftIcon} width={20} height={20}/>}
			{loading && <Loader w={22} />}
			{!loading && <Text>{children}</Text>}
			{rightIcon && <Svg icon={rightIcon} width={20} height={20}/>}
		</Base>
	);
};
