import React, { useEffect, useState, useContext } from "react";
import { Flex, Col, Svg, Text, Input } from "@components";

import { NavWrapper, IconButton, NavigationBar, NavSearch } from "./styles";
import { navigate } from "gatsby";

import logotipo from "@assets/logo.svg";
import notify from "@assets/icons/notify.svg";
import moon from "@assets/icons/moon.svg";


const Navbar = ({title}) => {
	const [route, setRoute] = useState('')

	useEffect(() => {
		if(typeof window !== "undefined") {
			setRoute(window.location.pathname.split('/').join(' / '))
		}
	}, [])

  return (
    <NavWrapper>
      <Flex justify="between">
        <Col autofit>
					<small>Inicio {route ? `${route}` : ''}</small>
					<Text fs={24} className='size:large'>{title || 'Dashboard'}</Text>
				</Col>
        <Col autofit className="t:right">

					<NavigationBar>
						<NavSearch>
							<Input placeholder="Search" disabled/>
						</NavSearch>
						<IconButton onClick={() => console.log('inactive item')}>
							<Svg icon={moon} width={24} height={24} />
						</IconButton>
						<IconButton onClick={() => console.log('inactive item')}>
							<Svg icon={notify} width={24} height={24} />
						</IconButton>
						<IconButton onClick={() => navigate("/")}>
							<Svg icon={logotipo} width={34} height={34} />
						</IconButton>
					</NavigationBar>
        </Col>
      </Flex>
    </NavWrapper>
  );
};

export default Navbar
