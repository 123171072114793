export const format = (n) => {
	let result = '';
	let currency = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});

	if(n > 0) {
			currency = currency.format(n);
			result = currency.replace(".00","");
	}

	result = result.replace(/[$]/g,'$ ')
	return result;
}

export const formatNumber = (n) => {
	return format(n).replace(/[$]/g, '')
}

export const StringToNumber = (n) => {
	return n.replace(/\D/g,'') * 1;
}

export const Capitalize = (value) => {
	return value
				.toLowerCase()
				.replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
}
