import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
  BLOGS: { DELETE_BLOG },
} = endpoints;

const deleteBlog = (id, token) =>
  new Promise((resolve, reject) => {
    request.setAuthorization(token);

    const response = request.post(`${DELETE_BLOG}/${id}`);
    response
      .then(({ data }) => resolve(data))
      .catch(() => reject({ error: "no se pudo eliminar este blog" }));
  });

export default deleteBlog;
