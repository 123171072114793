import React, { useContext } from "react";
import { Svg } from "@components";

import { ItemSidebar } from "./Sidebar/styles";
import { navigate } from "gatsby";

import AdminContext from "@context";

import selected from "@assets/icons/selected.svg";

const Item = ({
  icon,
  label,
  width,
  link,
  onClick,
  inline,
  direction,
}) => {
	const { route, setRoute } = useContext(AdminContext);

  const handleNavigate = url => {
		!!url && setRoute(url)
    !!url ? navigate(url) : console.log(false);
    onClick && onClick();
  };

  return (
    <ItemSidebar onClick={() => handleNavigate(link)} active={route === link}>
			<Svg icon={icon} width={20} height={20} />
			<span>{label}</span>
			<Svg className='selected' icon={selected} width={20} height={50} />
    </ItemSidebar>
  );
};

export default Item;
