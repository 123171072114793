import styled, { css } from "styled-components";

const Portal = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	overflow-x: hidden;
	z-index: 99999;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.95);
	transition: opacity 100ms ease;

	opacity: ${({ show }) => (!show ? 0 : 1)};
	pointer-events: ${({ show }) => (!show ? "none" : "all")};

	#slide {
		transition: margin-left 500ms ease-in-out;
	}

	.slide_item {
		transition: all .2s;
	}
`;

const PortalHeader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 1rem 2rem;
`;

const PortalButton = styled.div`
	opacity: 0.7;
	transition: opacity 100ms ease;
	cursor: pointer;
	z-index: 5;

	&:hover {
		opacity: 1;
	}
`;

export { Portal, PortalHeader, PortalButton };
