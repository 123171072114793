import { request } from "@services/api";
import endpoints from "@services/endpoints";

const {
	BLOGS: { GET_BLOGS },
} = endpoints;
const getBlog = (id, token) =>
	new Promise((resolve, reject) => {
		request.setAuthorization(token);

		const response = {
			other: {},
			basic: {},
			details: {},
			address: {},
			surface: {},
			design_space: [],
			commun_areas: [],
			servicios: [],
			amenidades: [],
			coolings: [],
			heatings: [],
			windows: [],
			constructions: [],
			roofs: [],
			floorings: [],
			views: [],
			moreInfo: {},
		};

		request
			.get(`${GET_BLOGS}/${id}`)
			.then(({ data: { data } }) => {
				const {
					name,
					seo_description,
					description,
					price,
					views,
					likes,
					isSmartHome,
					isModel,
					availables,
					bedrooms,
					parkings,
					badrooms,
					style,
					type,
					category,
					delegacion,
					condition,
					antiquity,
					status,
					referenceCode,
					country,
					postalCode,
					state,
					city,
					colony,
					street,
					numInt,
					numExt,
					totalSurface,
					habitableSurface,
					constructionSurface,
					roofGarden,
					design_space,
					commun_areas,
					servicios,
					amenidades,
					pets,
					basement,
					cooling,
					heating,
					window,
					construction,
					flooring,
					roof,
					maintenance_fee,
					conditioning_fee,
					digital_fee,
					matterport,
					lot_size,
					tax_hoa,
					active,
					publish,
					sold,
					have3dTour,
					openHouse,
					daysOnline,
				} = data;

				response.other = {
					daysOnline,
					sold,
					active,
					publish,
					likes
				}

				response.basic = {
					name,
					seo_description,
					price,
					bedrooms,
					badrooms,
					parkings,
					description,
					openHouse: !!openHouse,
					isSmartHome: !!isSmartHome,
					isModel: !!isModel,
					availables,
				};

				response.details = {
					type,
					style,
					category,
					delegacion,
					condition,
					antiquity,
					status,
					referenceCode,
				};

				response.address = {
					country,
					postalCode,
					state,
					city,
					colony,
					street,
					numInt,
					numExt,
				};

				response.surface = {
					totalSurface,
					habitableSurface,
					constructionSurface,
					roofGarden,
				};

				response.design_space = design_space.split(",");
				response.commun_areas = commun_areas.split(",");
				response.servicios = servicios.split(",");
				response.amenidades = amenidades.split(",");
				response.coolings = cooling.split(",");
				response.heatings = heating.split(",");
				response.windows = window.split(",");
				response.constructions = construction.split(",");
				response.floorings = flooring.split(",");
				response.roofs = roof.split(",");
				response.views = views.split(",");

				response.moreInfo = {
					pets: !!pets,
					have3dTour: !!have3dTour,
					maintenance_fee,
					matterport,
					conditioning_fee,
					digital_fee,
					basement,
					lot_size,
					tax_hoa
				};

				resolve(response);
			})
			.catch((err) => reject(false));
	});

	export default getBlog
