import React from 'react'
import st from 'styled-components'
import { switchProp } from "styled-tools";

const switchJustify = switchProp('justify', {
	around: 'justify-content: space-around;',
	between: 'justify-content: space-between;',
	start: 'justify-content: start;',
	center: 'justify-content: center;',
	end: 'justify-content: end;'
})

const switchAlign = switchProp('align', {
	around: 'align-items: space-around;',
	between: 'align-items: space-between;',
	start: 'align-items: start;',
	center: 'align-items: center; align-content: center;',
	end: 'align-items: end;'
})

const FlexWrapper = st.div`
	max-width: 100%;
	display: ${ ({inline}) => inline ? 'inline-flex' : 'flex' };
	flex-wrap: ${ ({wrapper}) => !wrapper ? 'wrap' : 'nowrap' };
	width: ${ ({inline}) => inline ? 'auto' : '100%' };
	${ ({align}) => align ? switchAlign : '' }
	${ ({justify}) => justify ? switchJustify : '' }
`

export default ({children, ...props}) => {
	return (
		<FlexWrapper {...props}>
			{children}
		</FlexWrapper>
	)
}
