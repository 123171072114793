import React from 'react'
import st from 'styled-components'
import { breakpoints } from "@constants/css"

const {sm,md,lg,hd} = breakpoints;
const GridWrapper = st.div`
	display: block;
	width: 100%;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;

	@media screen and (min-width: ${sm}px) {
		max-width: 90%;
	}
	@media screen and (min-width: ${md}px) {
		max-width: 85%;
	}
	@media screen and (min-width: ${lg}px) {
		max-width: 1100px;
	}
	@media screen and (min-width: ${hd}px) {
		max-width: 1300px;
	}
`

const Grid = ({children, ...props}) => {
	return (
		<GridWrapper {...props}>{children}</GridWrapper>
	)
}

export default Grid
