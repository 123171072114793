import React from "react";
import { Flex, Col, Input, Button, Text } from "@components";
import useAddOptions from "./hooks";

const AddOption = ({ typeOption, category, getValue }) => {
	const {
		enableButton,
		label,
		setLabel,
		description,
		setDescription,
		showError,
		messageError,
		handleCreateOption,
		handleCancel,
	} = useAddOptions(category, getValue);

	return (
		<Flex className="mv:15">
			<Col md={18} className="ph:05 mb:1">
				{!showError && (
					<Text fs={14} fw={500}>
						Agregar nueva {typeOption}
					</Text>
				)}
				{showError && (
					<Text fs={14} fw={500} color="#ff0050">
						{messageError}
					</Text>
				)}
			</Col>

			<Flex className="mt:1">
				<Col md={18} className="ph:05 mb:1">
					<Input
						value={label}
						getValue={(v) => setLabel(v)}
						placeholder={`Nueva ${typeOption}`}
						onlyNL
						capitalize
					/>
				</Col>
				<Col md={18} className="ph:05 mb:1">
					<Input
						value={description}
						getValue={(v) => setDescription(v)}
						placeholder={`Descripción de nueva ${typeOption}`}
						onlyNL
						capitalize
					/>

					<Flex className="mt:1" justify="end">
						<Col autofit className="pr:1">
							<Button square link onClick={() => handleCancel()}>
								Cancelar
							</Button>
						</Col>
						<Col autofit>
							<Button
								disabled={!enableButton}
								square
								secondary
								onClick={() => handleCreateOption()}
							>
								Agregar
							</Button>
						</Col>
					</Flex>
				</Col>

			</Flex>
		</Flex>
	);
};

export default AddOption