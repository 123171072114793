import st, { css } from "styled-components";

const ligthCss = css`
	background-color: #ecf0f1;
	color: #53565a;
`;

const primaryCSS = css`
	background-color: #0066ff;
	padding: 0 1rem;
	color: white;
	box-shadow: 0 2px 4px 0 rgba(83, 86, 90, 0.35);

	&:hover {
		background-color: #252323;
	}
`;

const ghostCSS = css`
	background-color: rgba(0,0,0, 0.2);
	padding: 0 1rem;
	color: white;

	&:hover {
		background-color: rgba(0,0,0, 0.4);
	}
`;

const secondaryCSS = css`
	background-color: transparent;
	padding: 0 1rem;
	color: #232525;
	background-color: #f5f5f5;
	box-shadow: 0 2px 4px 0 rgba(83, 86, 90, 0.35);

	&:hover {
		background-color: #232525;
		color: white;
	}
`;

const IconCircle = st.i`
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background-color: #53565A;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin-right: 15px;
    transition: background 100ms linear;
    svg {
        fill: #fff;
        transition: color 100ms linear;
    }
`;

const Base = st.button`
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
		flex-wrap: nowrap;
    background-color: #252323;
    border-radius: ${({ square }) => (square ? 15 : 30)}px;
    border: 2px solid transparent;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    min-width: 150px;
    padding: 5px 1rem;
    text-align: center;
    transition: background 200ms ease-in-out, 150ms color linear;
    height: 40px;
    svg {
        display: inline-block;
        vertical-align: middle;
    }
    &:disabled,
    &:disabled:hover {
				box-shadow: none;
        background: transparent;
        border: 2px solid #e0e0e0;
        color: #e0e0e0;
        cursor: not-allowed;
        i${IconCircle} {
            background-color: #e0e0e0;
            svg {
                fill: #ffffff;
            }
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: #0066FF;
        border: 2px solid transparent;
        color: white;
        i${IconCircle} {
            background-color: #0066FF;
            svg {
                fill: ${() => "#53565A"};
            }
        }
    }

    ${({ ligth, primary, secondary, ghost }) =>
			(ligth && ligthCss) ||
			(primary && primaryCSS) ||
			(ghost && ghostCSS) ||
			(secondary && secondaryCSS)}

		pointer-events: ${ ({noevents}) => noevents ? 'none' : 'all'};
`;

const Text = st.span`
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    margin: 0 5px;
`;
export { Base, Text, IconCircle };
