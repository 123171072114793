import st, { css } from "styled-components";

const SidebarWrap = st.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: center;
	min-width: 280px;
	width: 280px;
	height: 100%;
	z-index: 10;

	padding: 1em;
`;

const SideBarContent = st.div`
	background-color: white;
	border-radius: 10px;
	width: 100%;
	height: auto;
	padding-bottom: 5em;
	box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
`;

const ItemSidebar = st.div`
	display: flex;
	width: 100%;
	height: 36px;
	align-items: center;
	align-content: center;
	cursor: pointer;
	position: relative;

	justify-content: flex-start;
	color: #A3AED0;
	padding: 0.5rem 2rem;
	margin-bottom: 0.5rem;

	span {
		padding-left: 1rem;
	}

	.selected {
		opacity: 0;
		transition-delay: 1000ms;
		transition: opacity 200ms linear;
		display: block;
		position: absolute;
		right: -6px;
		top: -6px;
	}

	${({ active }) =>
		active &&
		css`
			font-weight: 600;
			color: #2b3674;

			.selected {
				opacity: 1;
				transition-delay: 1000ms;
				transition: opacity 200ms linear;
			}

			&::after {
				background-color: #4318ff;
			}
		`}

	&:hover {
		color: #2B3674;

		&::after {
			background-color: #4318FF;
		}
	}

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	width: 3px;
	// 	height: 100%;

	// 	position: absolute;
	// 	border-radius: 5px;
	// 	top: 0;
	// 	right: 0;
	// }
`;

const Divider = st.div`
	display: block;
	width: 100%;
	height: 1px;
	background-color: #efefef;
	margin: 1rem 0;
`;

export { SidebarWrap, SideBarContent, ItemSidebar, Divider };
