import React from "react";
import styled from "styled-components";

const defaultColor = "#0066ff";

function hexToRGB(hex, alpha) {
  hex = hex.replace(/#/g, "");
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const BadgeWrapper = styled.span`
  display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
  padding: 4px 8px;
  margin: 2px 4px;
  border-radius: 4rem;
  font-size: ${({regular}) => regular ? 14 : 12}px;
  background-color: ${({ color }) => {
    return color ? hexToRGB(color, 0.1) : hexToRGB(defaultColor, 0.1);
  }};

  color: ${({ color }) => (color ? color : defaultColor)};
`;

const Badge = ({ children, ...props }) => (
  <BadgeWrapper {...props}>{children}</BadgeWrapper>
);

export default Badge;
