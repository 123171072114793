import React from "react";
import { InactiveMiddleware } from "@middlewares";
import Sidebar from "@layout/Sidebar";
import Navbar from "@layout/Navbar";
import { Flex, Modal, Text, Loading } from "@components";

import { WrapperLayout } from "./styles";
import useMiddleware from "./hooks";

import defaultNotify from '@states/global/notify'

const Layout = ({ children, title }) => {
  const { menu, isAuthenticated, requestLoading, notify, setNotify } = useMiddleware();

  return (
    <InactiveMiddleware>
      <WrapperLayout>
        {isAuthenticated && <Sidebar items={menu} />}
        {requestLoading && <Loading />}
        <section id="app">
					<div id='content-app'>
						{isAuthenticated && <Navbar title={title} />}
						{isAuthenticated && children}
					</div>

          {notify.success && (
            <Modal
              type="success"
              show={true}
              title={notify.title || "Felicitaciones"}
							onClose={() => setNotify(defaultNotify)}
            >
              <div className="ph:2">
                <Text fs={16} align="center">
									{notify.message || "No hay nada más que hacer aquí!"}
                </Text>
              </div>
            </Modal>
          )}

          {notify.error && (
            <Modal
              show={true}
              title={notify.title || "Oh oh!"}
							onClose={() => setNotify(defaultNotify)}
            >
              <div className="ph:2">
                <Text fs={16} align="center">
									Houston tenemos un problema!
                </Text>
                <Text fs={16} align="center">
									{notify.message || ""}
                </Text>
              </div>
            </Modal>
          )}
					<footer id="footer">
						<Flex align="center" justify="between">
							<small>&copy; {new Date().getFullYear()} CRM Digital | Todos los derechos reservados</small>
							<small>Dudas? Comunícate a support@digitalbooting.com</small>
						</Flex>
					</footer>
        </section>
      </WrapperLayout>
    </InactiveMiddleware>
  );
};

export default Layout;
