import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { breakpoints } from '@constants/css';

const since = (bp, size) => {
    return css`
        @media (min-width: ${breakpoints[bp]}px) {
            font-size: ${ size }px;
        }
    `
}

const fontFamily = css`
    font-family: ${switchProp("family", {
        joey: 'FS Joey',
        poppins: 'Poppins'
    })};
`;

const defaultFontFamily = css`
    font-family: 'Poppins';
`;

const Text = styled.p`
    ${ ({type}) => {
        return type === 'inline' ? css`
            display: inline-block;
            width: auto;
        ` : css`
            display: block;
            width: 100%;
        `
    } }
    font-size: ${ prop('fs', 24) }px;
    line-height: ${ prop('line', 1.2)};
    font-weight: ${ prop('fw', 'normal') };
    text-align: ${ prop('align', 'left') };
    color: ${ prop('color', 'inherit') };
    opacity: ${ prop('opacity', 1) };
    position: relative;
    ${ prop => prop.family ? fontFamily : defaultFontFamily }

    ${ ({disabled}) => {
        return disabled ? css`
            pointer-events: none;
            opacity: 0.3;

            * {
                color: black !important;
            }
        ` : ''
    } }

    ${ props => props.xs ? since('xs', prop('xs', 14)) : '' }
    ${ props => props.sm ? since('sm', prop('sm', 14)) : '' }
    ${ props => props.md ? since('md', prop('md', 14)) : '' }
    ${ props => props.lg ? since('lg', prop('lg', 14)) : '' }
`;

export default Text;
