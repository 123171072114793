import axios from "axios";

class API {
	constructor() {
		if (!!API.instance) {
			return API.instance;
		}

		this.baseURL = "https://app.digitalbooting.com/api";
		// this.baseURL = "http://localhost:8001/api";
		this._token = null;

		this.caller = axios.create({
			baseURL: this.baseURL,
			headers: {
				"Content-Type": "application/json",
			},
		});

		API.instance = this;
		return this;
	}

	get token() {
		return this._token;
	}

	set token(val) {
		this._token = val;
	}

	setContent(content) {
		this.caller = axios.create({
			baseURL: this.baseURL,
			headers: {
				"Content-Type": content,
				Authorization: `Bearer ${this.token}`,
			},
		});
		return;
	}

	setAuthorization(token) {
		if (!this.token) this.token = token;
		this.caller = axios.create({
			baseURL: this.baseURL,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${this.token}`,
			},
		});
		return;
	}

	checkAuthorization() {
		return this.token !== null ? true : false;
	}

	clearAuthorization() {
		this.caller = axios.create({
			baseURL: this.baseURL,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return;
	}

	get(path) {
		return this.caller.get(path);
	}

	post(path, data = {}) {
		return this.caller.post(path, data);
	}

	put(path, data = {}) {
		return this.caller.put(path, data);
	}

	delete(path) {
		return this.caller.delete(path);
	}
}
export const request = new API();
